:root {
  --background-color-1: #4E5050;
  --background-color-2: #373C39;
  --primary-color-1: #C39874;
  --primary-color-2: #657B68;
  --primary-color-3: #F2D59D;
  --primary-color-4: #ECB299;
  --primary-color-5: #CEC4AF;
  --primary-color-6: #5f7e81;
  --primary-color-7: #f6c7b6;


  --primary-color-4-transparent: #ECB29988;
  --primary-color-5-transparent: #CEC4AF88;
  --primary-color-6-transparent: #5f7e8188;

  --primary-button-color: #3C525A;
  --primary-button-border-color: #373C39;
  --warning-color-1: #9D4D47;
  --warning-color-1-transparent: #9D4D4788;
  --success-color-1: #278d35;
  --success-color-1-transparent: #7E958188;
  --success-color-2: #7E9581;
  --border-color: #f1f2f6;
  --text-color: #f1f2f6;
  --spin-color: var(--primary-color-1);

  --chart-color-1: #005F73;
  --chart-color-2: #0A9396;
  --chart-color-3: #94D2BD;
  --chart-color-4: #E9D8A6;
  --chart-color-5: #EE9B00;
  --chart-color-6: #CA6702;
  --chart-color-7: #BB3E03;
  --chart-color-8: #AE2012;
  --chart-color-9: #9B2226;



}

.App {
  text-align: center;
  background-color: var(--background-color-1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App h2 {
  color: white;
}

.App h5 {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.mainContainer {
  text-align: center;
  background-color: var(--background-color-1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mainContainer h1,
.mainContainer h3,
.mainContainer h4 {
  color: white;
}

.pageContainer {
  max-width: min(96vw, 500px);
}

.verticalSection {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

}

.horizontalSection {

  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

}


.horizontalSectionRight {

  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;

}


.custom-tooltip {
  padding: 0px 10px;
  background-color: #2e313680;

}

.ant-progress-text {
  color: white !important
}

.ant-btn-primary {
  background-color: var(--primary-button-color);
  border-color: var(--primary-button-border-color);
}

.ant-spin-dot-item {
  background-color: var(--spin-color) !important;
}

.no-bottom-margin .ant-card-body {
  margin-bottom: 1px;
  padding-bottom: 1px;
}

.no-bottom-margin .ant-card-body div {
  margin-bottom: 1px !important;
  padding-bottom: 1px;
}

.ant-modal-title {
  text-align: center;
}

.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px);
  /* calculate height of the screen minus the heading */
}

.map {
  width: 100%;
  height: 100%;
}

.ant-progress-text {
  color: black !important;
}

.lightBackground .ant-progress-text {
  color: white !important;
}